import { ReactNode } from "react";
import ChatContextprovider from "./ChatContextProvider";
import React from "react";
import BotOptionProvider from "./BotOptions";
import TransactionContextprovider from "./TransactionContext";
import CallContextprovider from "./CallContextProvider";
interface AppContextProviderProps {
  children: ReactNode;
}

const ContextProviders: React.FC<AppContextProviderProps> = ({ children }) => {
  return (
    <BotOptionProvider>
      <TransactionContextprovider>
        <CallContextprovider>
          <ChatContextprovider>{children}</ChatContextprovider>
        </CallContextprovider>
      </TransactionContextprovider>
    </BotOptionProvider>
  );
};

export default ContextProviders;
