import {
  CSSProperties,
  useState,
} from "react";

import { Message } from "../../types/Message";

import "./ChatBotBody.css";
import { useBotOptions } from "../../Context/BotOptions";
import UserOptions from "../UserOptions/UserOptions";
import QueuesOption from "../Queues-Options";
import CallWidgetBody from "../Call-Widget-body";
import { TransactionViewContextInfo } from "../../Context/TransactionContext";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import axios from "axios";
import { CallViewActionContextInfo } from "../../Context/CallContextProvider";

const ChatBotBody = () => {
  // handles options for bot
  const { botOptions } = useBotOptions();
  // styles for chat body
  const bodyStyle: CSSProperties = {
    ...botOptions?.bodyStyle,
    scrollbarWidth: botOptions.chatWindow?.showScrollbar ? "auto" : "none",
  };

  // styles for user bubble
  const userBubbleStyle: CSSProperties = {
    backgroundColor: botOptions.theme?.primaryColor,
    color: "#fff",
    maxWidth: botOptions.userBubble?.showAvatar ? "65%" : "70%",
    ...botOptions.userBubbleStyle,
  };
  const userBubbleEntryStyle = botOptions.userBubble?.animate
    ? "rcb-user-message-entry"
    : "";

  // styles for bot bubble
  const botBubbleStyle: CSSProperties = {
    backgroundColor: botOptions.theme?.secondaryColor,
    color: "#fff",
    maxWidth: botOptions.botBubble?.showAvatar ? "65%" : "70%",
    ...botOptions.botBubbleStyle,
  };
  const botBubbleEntryStyle = botOptions.botBubble?.animate
    ? "rcb-bot-message-entry"
    : "";

  const [showQueue, setQueue] = useState(false);
  const [Loading, setLoading] = useState(false);
  const { CallQueue } = TransactionViewContextInfo();
  const { setCallAccessToken, setCallCustomerID } = CallViewActionContextInfo();

  const startJobExecution = async (option: string) => {
    const filteredQueue: any = CallQueue.filter(
      (queue: any) => queue?.QueueName.toLowerCase() === option?.toLowerCase()
    )[0];
    try {
      setLoading(true);
      const responseData = await axios.get(
        "https://jsonip.com/"
      )
      const arugments = new URLSearchParams(window.location.search)
      let TenantId:any=arugments.get('TenantId')
      let LoginUserId:any=arugments.get('LoginUserId')
      let UniqueName:any=arugments.get('UniqueName')
      let WidgetInfo:any=arugments.get('widgetInfo')
      console.log(WidgetInfo)
      let obj = {
        tenantId: TenantId,
        queueId: filteredQueue.Id,
        channelId: filteredQueue.ChannelName,
        CreatedIp: responseData?.data.ip,
        WidgetCustomerName:UniqueName!=null ?UniqueName:'',
          WidgetInfo:WidgetInfo!=null?WidgetInfo:''
      };

      const data = await axios.post(
        "https://ucx-rtc-api-dev.azurewebsites.net/api/JobRouting/JobCreation",
        obj
      );

      if (data.data) {
        localStorage.clear();
        console.log(data.data);
        setQueue(true);
        setCallAccessToken(data.data.accessToken);
        setCallCustomerID(data.data.userIdentity);
        localStorage.setItem("callJobId", data.data.jobId);
      }
    } catch (e: any) {
      setQueue(false);
      setCallAccessToken("");
      setCallCustomerID("");
      console.log(e);
    }finally{
      setLoading(false);
    }
  };

  return (
    <div
      style={bodyStyle}
      className="rcb-chat-body-container"
      // ref={chatBodyRef}
      // onScroll={updateIsScrolling}
    >
      {!Loading ? (
        <>
          {/* {!showQueue ? (
            <QueuesOption handleChooseOption={startJobExecution} />
          ) : (
            <CallWidgetBody setQueue={setQueue} />
          )} */}
          <CallWidgetBody setQueue={setQueue} />
        </>
      ) : (
        <div
          style={{
            height: "100%",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <LoadingSpinner />
        </div>
      )}
    </div>
  );
};

export default ChatBotBody;
