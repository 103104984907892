import ChatBot, { BotOptionsContext, Flow, MessagesContext, Options, Params, PathsContext } from "react-chatbotify";
import { useBotOptions } from "../Context/BotOptions";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  getDefaultBotOptions,
} from "../Services/BotOptionServices";
import { ChatClient, ChatThreadClient, SendMessageOptions } from "@azure/communication-chat";
import { AzureCommunicationTokenCredential, CommunicationIdentifier, CommunicationUserIdentifier } from "@azure/communication-common";
import axios from "axios";
import {
  ChatViewActionContextInfo,
  ChatViewContextInfo,
} from "../Context/ChatContextProvider";
import { TransactionViewContextInfo } from "../Context/TransactionContext";
import CloseModal from "../Components/Chat-Close-Modal";
import ServiceAgent from '../assets/customer-service-agent.png';
import closeChatIcon from "../assets/close_chat_icon.svg";
import "./Chat.css"; 
import CallWidget from "../Call-Widget";

const ChatBotifyWidget = () => {
  // const botOptions = getDefaultBotOptions();
  const endpointUrl = "https://acs-ucx-dev-eus-001.unitedstates.communication.azure.com";
  const { showChatBot,setBotOptions,botOptions,showCall } = useBotOptions();
  const [chatClient, setChatClient] = useState<ChatClient | null>();
  const { accessToken, customerID,isDeleted,threadId } = ChatViewContextInfo();
  const { setAccessToken, setCustomerID ,setIsDeleted,setThreadId} = ChatViewActionContextInfo();
  const [isLoad,setIsLoad] = useState(true)
  const [messages, setMessages] = useState<any[]>([]);
  const { QueueList } = TransactionViewContextInfo()
  const [chatThreadClient, setChatThreadClient] = useState<ChatThreadClient | null>(null);
  const [paths, setPath] = useState<string[]>([]);
  const [jobId, setJobId] = useState()
  const [endShown, setEndShown] = useState(false)

  useEffect(() => {
    const createChatClient = async () => {
      if (
        accessToken != null &&
        accessToken != undefined &&
        accessToken != "" && !chatClient
      ) {
        try {
          const client = new ChatClient(
            endpointUrl,
            new AzureCommunicationTokenCredential(accessToken)
          );
          setChatClient(client);
          console.log("Azure Communication Chat client created!");
        } catch (error) {
          console.log("Error creating ChatClient:", error);
        }
      }
    };
      createChatClient();
    return () => {};
  }, [accessToken]);

  useEffect(() => {
    const setupMessageListener = async () => {
      await chatClient?.startRealtimeNotifications();

      chatClient?.on('chatMessageReceived',async (notification) => {
       await handleChatMessageReceived(notification);
      });

      chatClient?.on('participantsRemoved',async (notification) => {
        console.log(notification.participantsRemoved)
            if(notification.participantsRemoved.length && !isDeleted){
              if(!paths.includes('chatDisconnect')){
                setPath(prev=> [...prev, 'chatDisconnect']);
              }
              setChatClient(null)
              setChatThreadClient(null)
              setAccessToken('')
              setCustomerID('')
              localStorage.clear();
            }
      })
    };
    setupMessageListener();

    return () => {
      // chatClient?.off('chatMessageReceived', () => {})
      // chatClient?.stopRealtimeNotifications();
    };
  }, [chatClient]);

  const startJobExecution = async (params: Params) => {
    
	setIsLoad(true)
     const filteredQueue:any = QueueList.filter((queue:any) => queue.QueueName.toLowerCase() === params.userInput.toLowerCase())[0]
    try {
      setAccessToken('');
      setCustomerID('');
    
      const responseData = await axios.get(
        "https://jsonip.com/"
      )
      // let responseData = await fetch('https://geolocation-db.com/json/');
      // let response = await responseData.json()
      const arugments = new URLSearchParams(window.location.search)
      let TenantId:any=arugments.get('TenantId')
      let LoginUserId:any=arugments.get('LoginUserId')
      let UniqueName:any=arugments.get('UniqueName')
      let WidgetInfo:any=arugments.get('widgetInfo')
      console.log(WidgetInfo)
      let obj = {
        tenantId:TenantId,
        queueId: filteredQueue.Id,
        channelId: filteredQueue.ChannelName,
        CreatedIp: responseData?.data.ip,
        WidgetCustomerName:UniqueName!=null ?UniqueName:'',
        WidgetInfo:WidgetInfo!=null?WidgetInfo:''
      };
      
      const data = await axios.post(
        "https://ucx-rtc-api-dev.azurewebsites.net/api/JobRouting/JobCreation",
        obj
      );

      if (data.data) {
        localStorage.clear();
        console.log(data.data);
        setAccessToken(data.data.accessToken);
        setCustomerID(data.data.userIdentity);
        localStorage.setItem('jobId', data.data.jobId);
      }
    } catch (e:any) {
      setAccessToken('');
      setCustomerID('');
      console.log(e);
      params.injectMessage(e.message);
	  setIsLoad(false)
    }


  };

  const handleChatMessageReceived = async (chatMessage: any) => {
    // try {
      console.log("Notification received:", chatMessage);
      if (!chatMessage || !chatMessage.message) {
        console.log('Invalid notification format or missing message:', chatMessage);
        return;
      }

      const newThreadId = chatMessage.threadId;

      if (!newThreadId) {
        console.error('Thread ID not found in notification payload');
        return;
      }
      setThreadId(newThreadId);

      if (!newThreadId) {
        console.error('Thread ID not found in notification payload');
        return;
      }
      const chatThreadClients = chatClient!.getChatThreadClient(newThreadId ?? threadId);
      if (!chatThreadClients) {
        console.error('Error: Unable to get chatThreadClient');
        return;
      }
      setChatThreadClient(chatThreadClients);
      setEndShown(true)
      try {
        if(chatMessage.senderDisplayName === "user") return
        const senderDisplayName = chatMessage.senderDisplayName;
        const newMessageContent = chatMessage.message;
        setMessages(prev => {
          const newMessage = {
            content: newMessageContent,
            sender: senderDisplayName,
            type: "string",
          }
          return [...prev, newMessage]
        })
        console.log(messages.length)
        if(!messages.includes('enableChatInput')){
        setPath(prev => [...prev, "enableChatInput"]);
        }
      }catch (e) {

      }

  }

  const sendMessage = async (params: Params) => {

    try {
      // console.table(messages)
      const sendMessageRequest = {
        content: params.userInput,
        
      };
      const sendMessageOptions:SendMessageOptions = {
        senderDisplayName: 'user',
        type: 'text' as const,
      };
      await chatThreadClient?.sendMessage(sendMessageRequest,sendMessageOptions)
      return 
    }catch(e){
      console.log(e)
    }

  }

  const startAgain = useCallback(async () => {
    setPath([]);
    setMessages([]);
    setThreadId('')
    setEndShown(false);
    localStorage.clear();
    setPath(prev => [...prev, 'start']);
  }, []);

  useEffect(() => {
    const handleStartAgain = async () => {
      if (isDeleted) {
        await startAgain();
      }
    };
    handleStartAgain();

    return () => {
      setIsDeleted(false);
    };
  }, [isDeleted]);


  const flow: Flow = {
 
    start: {
      message: "Hello! How can I assist you today?",
      options: QueueList.map((s:any) => { return s.QueueName}),
      path: "ask_customer_Connect",
      chatDisabled: true,
    },
    ask_customer_Connect: {
      message: (params: Params) => {
        startJobExecution(params);
        return params.injectMessage(
          `Hey We are connecting Our ${params.userInput} Agent to you! Please wait few Minutes...`
        );
      },
      chatDisabled: true,
      render: (params: Params) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "2rem",
            gap: 4,
            margin: "1rem 1rem 0rem 1rem",
            height: 40,
            backgroundColor: "whitesmoke",
          }}
        >
          <span>Connecting to {params.userInput} Agent...</span>
        </div>
      ),
      path: "ask_connect_agent",
    },
    ask_connect_agent: {
      message: async (params) => {
      	await  sendMessage(params)
      },
      chatDisabled:false,
      path:'ask_connect_agent'
    },
    enableChatInput:{
      chatDisabled:false,
      path:'ask_connect_agent'
    },
    chatDisconnect:{
      chatDisabled: true,
      render: (params: Params) => (
        <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "2rem",
            gap: 4,
            margin: "1rem 1rem 0rem 1rem",
            height: 20,
            backgroundColor: "whitesmoke",
          }}
        >
          <span style={{fontSize:12}}>Agent Disconnected..</span>
        </div>
         <button className="btn-1" onClick={startAgain}>Start Again</button></div>
      ),
    },
  };

  return (
    <div>
      	<BotOptionsContext.Provider value={{botOptions: botOptions, setBotOptions: setBotOptions}}>
      	<PathsContext.Provider value={{paths: paths, setPaths: setPath}}>
          <MessagesContext.Provider value={{messages: messages, setMessages: setMessages}}>
           {showChatBot ? 
           <ChatBot flow={flow} options={{
            ...botOptions,
            isOpen:showChatBot,
            header:{
              title:<CloseModal />,
              showAvatar: true,
              avatar: ServiceAgent,
              closeChatIcon: closeChatIcon,
            },advance: {
              ...botOptions.advance,
              useCustomBotOptions: false,
              useCustomPaths: true,
              useCustomMessages: true,
      
            },
            footer:{
              text:(
                <div style={{cursor: "pointer", display: "flex", flexDirection: "row", alignItems: "center", columnGap: 3}} 
                  onClick={() => window.open("https://sbnasoftware.com/contact")}
                >
                  {/* <span>Powered By</span> */}
                  {/* <img style={{width: 10, height: 10}} src={chatButton}/> */}
                  {/* <span style={{fontWeight: "bold"}}>SBNA Software Solutions</span> */}
                </div>
              ),
            }
           }} />
           : undefined}
           {showCall ?
           <CallWidget/>: undefined
           } 
        </MessagesContext.Provider>
        </PathsContext.Provider>
        </BotOptionsContext.Provider>

    </div>
  );
};

export default ChatBotifyWidget;