import axios from "axios";
import { useRef } from "react";
import { ChatViewActionContextInfo, ChatViewContextInfo } from "../../Context/ChatContextProvider";

const CloseModal = () => {
    const dropdownOption = useRef<any>(null);
    const {setIsDeleted,} = ChatViewActionContextInfo();
    const { accessToken, customerID,isDeleted,threadId } = ChatViewContextInfo();
    // const  = 
    const toggleDropdown =()=> {
        // const dropdown = document.getElementById('dropdownOptions');
        if(dropdownOption){
        dropdownOption.current.style.display = dropdownOption.current.style.display === 'block' ? 'none' : 'block';
        }
      }


    const endChat = async () => {
      dropdownOption.current.style.display = 'none'
       let datas:any =  localStorage.getItem('jobId')
       console.log(datas)
       if(datas){
        try{
          let obj = {
            jobId:datas,
            Token:accessToken,
            threadId:threadId
          }
          const data = await axios.post('https://ucx-rtc-api-dev.azurewebsites.net/api/JobRouting/customerend',obj)
           if(data.data){
            setIsDeleted(true)
           }
        }catch(err){
          console.log(err)
        }
       }
       else{
        setIsDeleted(true)
       }
      }
  return (
    <div style={{display: "flex", alignItems: "center", justifyContent: "center",paddingTop:5}}>
            <div style={{cursor: "pointer",display:"flex"}}>
              <span style={{margin: 0,fontSize: 20, fontWeight: "bold"}}>CCX Customer Support</span> 
        </div>
        <span className="material-symbols-outlined more-vert" style={{fontSize:'1.5rem', cursor: 'pointer',paddingLeft:10}} onClick={toggleDropdown}>more_vert</span>
            <div className="dropdown-options" ref={dropdownOption}>
              <a href="#" onClick={endChat}>End Chat</a>
            </div>

            
        </div>





  );
};

export default CloseModal;
