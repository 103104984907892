

import { useContext, createContext, Dispatch, SetStateAction, useState, ReactNode } from "react";

import { Options } from "../types/Options"
import { parseBotOptions,defaultOptions, getDefaultBotOptions } from "../Services/BotOptionServices";
/**
 * Creates the useBotOptions() hook to manage bot options.
 */
type BotOptionsContextType = {
	botOptions: Options;
	setBotOptions: (type:any)=> void
    setChatBot: (type:boolean)=> void,
    showChatBot:boolean;
    showCall:boolean;
    setCall: (type:any)=> void
}
interface ChatContextProviderProps {
    children: ReactNode;
  }
const BotOptionsContext = createContext<BotOptionsContextType>(
    {
        botOptions: {},
        setBotOptions: () => {},
        setChatBot: () =>{},
        showChatBot: false,
        showCall: false,
        setCall: ()=>{}
    });
export const useBotOptions = () => useContext(BotOptionsContext);

const BotOptionProvider: React.FC<ChatContextProviderProps>= ({ children }) => {
    const defaultBotOptions = getDefaultBotOptions();
    const [botOptions, setBotOptions] = useState<any>(parseBotOptions(defaultBotOptions))
    const [showChatBot,setChatBot] = useState(false)
    const [showCall,setCall] = useState(false);
return(
    <BotOptionsContext.Provider value={
        {
            botOptions,
            setBotOptions,
            setChatBot,
            showChatBot,
            showCall,
            setCall
          }
    }>
      {children}
    </BotOptionsContext.Provider>
)
}


export default BotOptionProvider;