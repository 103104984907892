

const RoundedIcon = ({
    icon,
    active
}:{
    icon:any;
    active?:boolean;
}) => {

    return(
        <div style={{
            height:50,
            width:50,
            borderRadius:"50%",
            display:"flex",
            justifyContent:"center",
            alignItems:"center",
            backgroundColor:active? 'rgb(142 142 142)' : '#EEEDF2',
        }}>
            {icon}
        </div>
    )
}

export default RoundedIcon;