// import logo from './logo.svg';
import './App.css';
import React from "react";
import  ChatWidget  from './Components/Chat-Bot-Widgets/index';
import ContextProviders from './Context';
import ChatBotifyWidget from './Chat-botify';

function App() {
  return (
    <div className="App">
      <ContextProviders>
          <ChatWidget/>
          <ChatBotifyWidget/>
      </ContextProviders>
    </div>
  );
}
export default App;
