import React ,{ createContext, ReactNode, useContext, useState } from "react";


export interface CallContextStateData {
    CallaccessToken: string;
    CallcustomerID:String;
  };

export const CallContextState: CallContextStateData = {
    CallaccessToken:'',
    CallcustomerID:'',
  };

  export interface CallContextActionData {
    setCallAccessToken: (type:string)=> void,
    setCallCustomerID: (type:string)=> void,
  };

export const CallContextAction: CallContextActionData = {
    setCallAccessToken: () => {},
    setCallCustomerID: () => {},
  };

export const CallViewContext = createContext(CallContextState)
export const CallViewActionContext = createContext(CallContextAction)

export const CallViewContextInfo = () => useContext(CallViewContext)
export const CallViewActionContextInfo = () => useContext(CallViewActionContext)
interface CallContextProviderProps {
  children: ReactNode;
}

const CallContextprovider: React.FC<CallContextProviderProps>= ({ children }) => {
  const [CallaccessToken,setCallAccessToken] = useState<string>('');
  const [CallcustomerID, setCallCustomerID] = useState<string>('')

  const contextValue = {
    CallaccessToken,
    CallcustomerID
  };

    return(
   
        <CallViewContext.Provider value={contextValue}>
          <CallViewActionContext.Provider value={
            {
            setCallAccessToken,
              setCallCustomerID,
            }
          }>
            {children}
          </CallViewActionContext.Provider>
        </CallViewContext.Provider>
    )
}

export default CallContextprovider;