import { useEffect, useState } from "react"
import { isDesktop } from "../Services/Utils";
import { useBotOptions } from "../Context/BotOptions";
import ChatBotHeader from "../Components/ChatBotHeader/ChatBotHeader";
import ChatBotBody from "../Components/ChatBotBody/ChatBotBody";
import "./ChatBotContainer.css";

const CallWidget = () => {
    const {botOptions,showCall} = useBotOptions()
    const [isOpen,setOpen] = useState(false)
    const [viewportHeight, setViewportHeight] = useState<number>(window.visualViewport?.height as number 
		|| window.innerHeight);
	const [viewportWidth, setViewportWidth] = useState<number>(window.visualViewport?.width as number 
		|| window.innerWidth);	


    	const getWindowStateClass = () => {
            const windowClass = "rcb-call-bot ";
            if (showCall) {
                return windowClass + "rcb-window-open";
            } else {
                return windowClass + "rcb-window-close"
            }
        }
        

    const getChatWindowStyle = () => {
		if (!isDesktop) {
			return {
				...botOptions.chatWindowStyle,
				borderRadius: "0px",
				left: "0px",
				right: "auto",
				top: "0px",
				bottom: "auto",
				width: `${viewportWidth}px`,
				height: `${viewportHeight}px`,
			}
		} else {
			return botOptions.chatWindowStyle;
		}
	}


    return(
        <>
       <div className={getWindowStateClass()}>
       {showCall &&
            <>
					{/* <style>
						{`
							html {
								overflow: hidden !important;
								touch-action: none !important;
								scroll-behavior: auto !important;
							}
						`}
					</style> */}
					{/* <div 
						style={{
							// position: "fixed",
							top: 0,
							left: 0,
							width: "100%",
							height: "100%",
							backgroundColor: "#fff",
							// zIndex: 9999
						}}
					>	
					</div> */}
				</>
}
			<div
				style={getChatWindowStyle()}
				className="rcb-call-window"
			>
				<ChatBotHeader />
				<ChatBotBody />
			</div>
		</div>
    </>
    )
}

export default CallWidget;