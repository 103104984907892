import React, { useState, useEffect, useRef } from 'react';
interface CallComponentProps {
    callStatus: string; // Assuming callStatus is a string, adjust type if needed
  }
const CallComponent: React.FC<CallComponentProps> = ({  callStatus }) => {
  const [seconds, setSeconds] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [hours, setHours] = useState(0);
  const [callActive, setCallActive] = useState(false);
  const timerRef = useRef<null | any>(null);  // Use a ref to store the interval ID
  // Start timer when call is accepted (when session state is 'Established')
  useEffect(() => {
    if (callStatus === "Established") {
      setCallActive(true); // Set call active flag
      startTimer(); // Start the timer
    }

    // Cleanup timer when component unmounts or call ends
    return () => {
      clearInterval(timerRef.current);
    };
  }, [callStatus]);

  // Start the timer when the call is accepted
  const startTimer = () => {
    timerRef.current = setInterval(() => {
      setSeconds(prevSeconds => {
        if (prevSeconds === 59) {
          setMinutes(prevMinutes => {
            if (prevMinutes === 59) {
              setHours(prevHours => prevHours + 1);
              return 0; // Reset minutes
            }
            return prevMinutes + 1;
          });
          return 0; // Reset seconds
        }
        return prevSeconds + 1;
      });
    }, 1000);
  };

  // Stop the timer when the call is terminated
  const stopTimer = () => {
    clearInterval(timerRef.current);
    setCallActive(false);
  };

  // Reset the timer when call ends
  useEffect(() => {
    if (callStatus === "Terminated" || callStatus === "Rejected" || callStatus === "Call Canceled" || callStatus === "Call Ended") {
      stopTimer();  // Stop the timer
      resetTimer(); // Optionally reset the timer to 0
    }
  }, [callStatus]);

  const resetTimer = () => {
    setSeconds(0);
    setMinutes(0);
    setHours(0);
  };

  return (
    <div>
      {callActive ? (
        <div>
          <h3>Call Duration</h3>
          <p>{`${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`}</p>
        </div>
      ) : (
        <p>No active call</p>
      )}
    </div>
  );
};

export default CallComponent;
