import axios from "axios";
import React ,{ createContext, ReactNode, useContext, useEffect, useState } from "react";


export interface TransactionContextStateData {
    QueueList:string[];
    CallQueue:string[];
  };

export const TransactionContextState: TransactionContextStateData = {
    QueueList:[],
    CallQueue:[],
  };

  export interface TransactionContextActionData {
    setQueueList: (type:any) => void,
    setCallQueue: (type:any) => void,
  };

export const TransactionContextAction: TransactionContextActionData = {
    setQueueList: () => {},
    setCallQueue: () => {},
  };

export const TransactionViewContext = createContext(TransactionContextState)
export const TransactionViewActionContext = createContext(TransactionContextAction)

export const TransactionViewContextInfo = () => useContext(TransactionViewContext)
export const TransactionViewActionContextInfo = () => useContext(TransactionViewActionContext)
interface TransactionContextProviderProps {
  children: ReactNode;
}

const TransactionContextprovider: React.FC<TransactionContextProviderProps>= ({ children }) => {
  const [QueueList, setQueueList] = useState<any[]>([]);
  const [CallQueue, setCallQueue] = useState<any[]>([]);

    return(
   
        <TransactionViewContext.Provider value={{
            QueueList,
            CallQueue
        }}>
          <TransactionViewActionContext.Provider value={
            {
             setQueueList,
             setCallQueue
            }
          }>
            {children}
          </TransactionViewActionContext.Provider>
        </TransactionViewContext.Provider>
    )
}

export default TransactionContextprovider;